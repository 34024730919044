import { useCallback, useRef } from "react";
import { DecodedValueMap, QueryParamConfigMap, useQueryParams } from 'use-query-params';


export function useRemoveParams(paramList: QueryParamConfigMap, timeout = 1200): [DecodedValueMap<QueryParamConfigMap>, (newParams: string[]) => void] {

  const [ params, setParams ] = useQueryParams(paramList);
  const paramsToRemoveQueueRef = useRef<string[]>([]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const removeParams = useCallback((newParams: string[]) => {
    // console.log('removeParams', newParams);
    for (const param of newParams) {
      if (!paramsToRemoveQueueRef.current.includes(param) && params[param] !== undefined) {
        paramsToRemoveQueueRef.current.push(param);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if(paramsToRemoveQueueRef.current.length > 0) {
        timeoutRef.current = setTimeout(() => {
          // console.log('removeParams timeout', paramsToRemoveQueueRef.current);
          setParams(Object.fromEntries(paramsToRemoveQueueRef.current.map(toRemove => [toRemove, undefined])), 'replaceIn');
          paramsToRemoveQueueRef.current = [];
        }, timeout);
      }
    }
  }, [ setParams, params, timeout ]);

  return [
    params,
    removeParams
  ];
}

export * from 'use-query-params';
