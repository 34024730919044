import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { FOLLOW_CONTACT } from "../queries/profile";
import { GET_FOLLOWS } from "../queries/user";


export function useFollowings() {

  const [followContact] = useMutation(FOLLOW_CONTACT, {
    refetchQueries: [{ query: GET_FOLLOWS }],
    optimisticResponse: null,
  });

  const follow = useCallback((contact) => {
    followContact({
      variables: {
        contactId: contact._id,
        follow: true,
      },
      update(cache) {
        const userData = cache.readQuery({ query: GET_FOLLOWS });
        const updatedUserData = {
          currentUser: {
            ...userData.currentUser,
            follows: [...userData.currentUser.follows, contact],
            followSuggestions: userData.currentUser.followSuggestions.filter(c => c._id !== contact._id),
          }
        };
        cache.writeQuery({ query: GET_FOLLOWS, data: updatedUserData });
      },
      // refetch feed as
      refetchQueries: [{ query: GET_FOLLOWS }]
    });
  }, [ followContact ]);

  const unfollow = useCallback((contact) => {
    followContact({
      variables: {
        contactId: contact._id,
        follow: false,
      },
      update(cache) {
        const userData = cache.readQuery({ query: GET_FOLLOWS });
        const updatedUserData = {
          currentUser: {
            ...userData.currentUser,
            follows: userData.currentUser.follows.filter(c => c._id !== contact._id),
            followSuggestions: [...userData.currentUser.followSuggestions, contact],
          }
        };
        cache.writeQuery({ query: GET_FOLLOWS, data: updatedUserData });
      }
    });
  }, [ followContact ]);

  return { follow, unfollow };
}
