import { useEffect } from "react";
import { useInputPositions } from "./use-input-positions";


export function useSuggestMentions(inputRef, message, setCurrentWord, autocompleteWord, setAutocompleteWord, setMessage) {

  const { currentWord, currentWordPosition } = useInputPositions(message, inputRef);


  useEffect(() => {
    setCurrentWord(currentWord);
  }, [currentWord, setCurrentWord])


  useEffect(() => {
    if(autocompleteWord) {
      const beforeWord = message.slice(0, currentWordPosition);
      const afterWord = message.slice(currentWordPosition + currentWord.length);

      if(afterWord?.[0] === ' ') {
        setMessage(beforeWord + autocompleteWord + afterWord)
      } else {
        setMessage(beforeWord + autocompleteWord + ' ' + afterWord)
      }

      setAutocompleteWord(undefined);
      setCurrentWord('');
      inputRef.current.focus();
      // We can only set caret position after message has been updated in DOM, so use setTimeout
      setTimeout(() => {
        const newCaretPosition = currentWordPosition + autocompleteWord.length + 1; // add one for added whitespace
        inputRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
      }, 0)
    }
  }, [autocompleteWord, message, currentWord, currentWordPosition, inputRef, setAutocompleteWord, setMessage, setCurrentWord]);

}
