import { createContext, FC, ReactNode, useContext } from "react";


const VisibilityContext = createContext(false);


export const VisibilityProvider: FC<{ children: ReactNode, isVisible: boolean }> = function({ children, isVisible }) {
  // const [isVisible, setIsVisible] = useState(false);

  return (
    <VisibilityContext.Provider value={isVisible}>
      {children}
    </VisibilityContext.Provider>
  );
}


export const useIsVisible = () => useContext(VisibilityContext);
