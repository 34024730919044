import { useCallback } from "react";

import { useUpdateRatingMutation } from "../generated/graphql";
import { useSession } from "./auth";


export function useUpdateRating(shareId?: string, canonicalContentId?: string) {
  const [updateRating] = useUpdateRatingMutation();
  const { user } = useSession();

  return useCallback((value: number) => {

    const optimisticResponse = {
      updateRating: {
        __typename: 'UpdatedRating' as const,
        share: shareId ? {
          __typename: 'Share' as const,
          _id: shareId,
          rating: {
            __typename: 'Rating' as const,
            _id: 'new-id',
            rating: value,
            person: {
              __typename: 'Contact' as const,
              _id: user._id,
            }
          },
          sendersRating: null,
        } : null,
        canonicalContent: canonicalContentId ? {
          __typename: 'CanonicalContent' as const,
          _id: canonicalContentId,
          rating: {
            __typename: 'Rating' as const,
            _id: 'new-id',
            rating: value,
            person: {
              __typename: 'Contact' as const,
              _id: user._id,
            }
          },
        } : null,
      }
    };

    return updateRating({
      variables: {
        input: {
          canonicalContentId,
          shareId,
          rating: value,
          scale: '1,1,5',
        }
      },
      optimisticResponse,
    });

  }, [canonicalContentId, shareId, updateRating, user?._id]);

}
