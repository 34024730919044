import { useContext, createContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from "@apollo/client";
import { shallowEqualObjects } from "shallow-equal";

import { useSession } from './auth';
import { GET_USERS_LISTS } from '../queries/list';
import { SET_IN_LIST } from '../queries/share';
import { useAnalyticsQueued } from './delicious-analytics';

export const IsListBookmarkedContext = createContext();

export function useIsListBookmarked() {
  return useContext(IsListBookmarkedContext);
}

export const IsListBookmarkedProvider = ({ children }) => {

  const { auth } = useSession();

  const { data, error } = useQuery(GET_USERS_LISTS, {
    skip: !auth?.getIdToken(),
  });

  if (error) {
    console.error(error);
  }

  const [bookmarked, setBookmarked] = useState({});

  useEffect(() => {
    if(!data?.currentUser?.followedLists) {
      if(Object.keys(bookmarked).length !== 0) {
        setBookmarked({});
      }
    } else {

      const followedListsIds = data.currentUser.followedLists.map(list => list._id);
      const idsAsKeys = followedListsIds.reduce((res, curr) => { res[curr] = true; return res; }, {});

      if(!shallowEqualObjects(idsAsKeys, bookmarked)) {
        setBookmarked(idsAsKeys);
      }
    }
  }, [data, bookmarked]);

  const isListBookmarked = useCallback((listId) => {
    return !!bookmarked[listId];
  }, [bookmarked]);

  return <IsListBookmarkedContext.Provider value={isListBookmarked}>{children}</IsListBookmarkedContext.Provider>;
}
IsListBookmarkedProvider.propTypes = {
  children: PropTypes.node,
};


export const IsBookmarkedContext = createContext();

export function useIsBookmarked() {
  return useContext(IsBookmarkedContext);
}

export const IsBookmarkedProvider = ({ children }) => {

  const { auth } = useSession();

  const { data, error } = useQuery(GET_USERS_LISTS, {
    skip: !auth?.getIdToken(),
  });

  if (error) {
    console.error(error);
  }

  const [bookmarked, setBookmarked] = useState({});

  useEffect(() => {
    if(!data?.currentUser?.lists) {
      if(Object.keys(bookmarked).length !== 0) {
        setBookmarked({});
      }
    } else {

      const listsWithoutArchive = data.currentUser.lists.filter(list => list.name !== 'Archive');
      const idsAsKeys = {};

      for(const list of listsWithoutArchive) {
        for(const item of list.items) {
          if(item.share?._id) {
            idsAsKeys[item.share._id] = true;
          }
          if(item.canonicalContent?._id) {
            idsAsKeys[item.canonicalContent?._id] = true;
          }
        }
      }

      if(!shallowEqualObjects(idsAsKeys, bookmarked)) {
        setBookmarked(idsAsKeys);
      }
    }
  }, [data, bookmarked]);

  const isBookmarked = useCallback((shareId, canonicalId) => {
    return !!(bookmarked[shareId] || bookmarked[canonicalId]);
  }, [bookmarked]);

  return <IsBookmarkedContext.Provider value={isBookmarked}>{children}</IsBookmarkedContext.Provider>;
}
IsBookmarkedProvider.propTypes = {
  children: PropTypes.node,
};


export const ToggleBookmarkContext = createContext();

export function useToggleBookmark() {
  return useContext(ToggleBookmarkContext);
}

export const ToggleBookmarkProvider = ({ children }) => {

  const { track } = useAnalyticsQueued();

  const [setInList] = useMutation(SET_IN_LIST, {
    onCompleted: () => {
      track('add_to_list', { category: 'feed' });
    }
  });


  const toggleBookmark = useCallback((share, canonicalContent, list, present) => {

    const optimisticList = { ...list };
    if(present) {
      optimisticList.items = [
        ...list.items,
        {
          _id: 'temp-id',
          __typename: 'ListItem',
          share: share || null,
          canonicalContent: canonicalContent || null,
          createdAt: (new Date()).toISOString()
        }
      ];
    } else {
      optimisticList.items = list.items.filter(i => share ? i.share?._id !== share._id : i.canonicalContent?._id !== canonicalContent._id);
    }

    setInList({
      variables: {
        input: {
          share: share?._id,
          canonicalContent: canonicalContent?._id,
          listName: list.name,
          present,
        }
      },
      refetchQueries: [
        { query: GET_USERS_LISTS },
      ],
      optimisticResponse: {
        setInList: optimisticList
      },
    });
  }, [setInList]);

  return <ToggleBookmarkContext.Provider value={toggleBookmark}>{children}</ToggleBookmarkContext.Provider>;
}
ToggleBookmarkProvider.propTypes = {
  children: PropTypes.node,
};


export const BookmarkProvider = ({ children }) => (
  <IsListBookmarkedProvider>
    <IsBookmarkedProvider>
      <ToggleBookmarkProvider>
        {children}
      </ToggleBookmarkProvider>
    </IsBookmarkedProvider>
  </IsListBookmarkedProvider>
);
BookmarkProvider.propTypes = {
  children: PropTypes.node,
};
