import { useState, useEffect, useRef, useCallback } from "react";
import { App } from '@capacitor/app';
import { useLocation } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import qs from 'qs';

import { usePushNotificationEvents } from './notifications';
import { isRelativeUrl } from "../utils/url";
import { useAddLike } from "./reaction";
import { useToggleInWatchlist } from "./watch-state";
import { StringParam, useRemoveParams } from "./remove-params";
import { useSession } from "./auth";
import { useAnalyticsQueued } from "./delicious-analytics";


export function useDeepLinks() {

  const [ deepLink, setDeepLink ] = useState();
  const init = useRef(false);
  const location = useLocation();

  const { url: queryParamUrl } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const validateAndUpdateDeepLink = useCallback((url) => {

    if (url) {
      const scheme = url.match(/^[a-z0-9]*:\/\//i)?.[0];
      // Facebook iOS SDK callbacks are handled by the native code
      if(scheme === 'fb1184219779085621://') {
        console.log('deep link from facebook SDK, ignore', url);
        return;
      }
      const relativeUrl = url.replace(/(club.icecream|icecreamclub):\/\//, '/').replace(/^[a-z]*:\/\/[^/]*/i, '');
      console.log('deep link relativize', `${url} -> ${relativeUrl}`);
      if (isRelativeUrl(relativeUrl)) {
        console.log('deep linking', relativeUrl);
        setDeepLink(relativeUrl);
      } else {
        console.error(`deep link invalid, only relative links allowed. Ignoring [${url}]`);
      }
    }
  }, []);

  useEffect(() => {
    validateAndUpdateDeepLink(queryParamUrl);
  }, [ queryParamUrl, validateAndUpdateDeepLink ]);

  const { action } = usePushNotificationEvents();
  useEffect(() => {
    if (action) {
      const url = action.notification?.data?.url || action.notification?.data?.data?.url;
      console.log('Handle action from push notification', { actionId: action.actionId, url });
      if(action.actionId === 'tap' && url) {
        validateAndUpdateDeepLink(url);
      }
    }
  }, [ action, validateAndUpdateDeepLink ]);

  useEffect(() => {
    // don't init listeners twice
    if (init.current) {
      return;
    }
    init.current = true;
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', ev => {
        console.log('deep link appUrlOpen start', ev);
        if (ev.url) {
          validateAndUpdateDeepLink(ev.url);
        }
      }).catch(err => {
        console.error('deep link appUrlOpen addListener error', err);
      });
    }

  }, [ validateAndUpdateDeepLink ]);

  return [ deepLink, setDeepLink ];
}


export function useProcessUrlActions(share, canonicalId) {

  const { user } = useSession();
  const { track } = useAnalyticsQueued();

  const [ removableParams, removeParams ] = useRemoveParams({ 'add-like': StringParam });
  const processedLikeRef = useRef('');
  const processedWatchlistRef = useRef('');

  const addLike = useAddLike();
  const toggleWatchlist = useToggleInWatchlist();
  useEffect(() => {
    // wait with updating reaction/like until auth is complete
    if(removableParams['add-like'] && user && share && processedLikeRef.current !== share._id) {
      track('opened_add_like_link', { shareId: share._id, category: 'notification' });
      addLike(share, true);
      processedLikeRef.current = share._id;
      removeParams([ 'add-like' ]);
    }

    if(removableParams['add-watchlist'] && user && share && processedWatchlistRef.current !== share._id) {
      track('opened_add_watchlist_link', { shareId: share._id, category: 'notification' });
      toggleWatchlist(canonicalId, share._id, true);
      processedWatchlistRef.current = share._id;
      removeParams([ 'add-watchlist' ]);
    }
  }, [removeParams, user, track, removableParams, share, addLike, canonicalId, toggleWatchlist]);

}
