import { QueryHookOptions } from '@apollo/client';
import { GetContactQuery, GetContactQueryVariables, useGetContactQuery } from '../generated/graphql';


export function useContact(contactId: string | undefined, options: QueryHookOptions<GetContactQuery, GetContactQueryVariables> = {}) {

  const queryResult = useGetContactQuery({
    ...options,
    skip: options?.skip || !contactId,
    variables: { id: contactId || '' },
  });

  return queryResult;
}
