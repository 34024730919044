import React, { createContext, FC, ReactNode, RefObject, useMemo } from "react";


const ScrollElementContext = createContext({
  scrollContainerRef: undefined as RefObject<HTMLElement> | undefined,
  scrollContentRef: undefined as RefObject<HTMLElement> | undefined
});

export const ScrollElementProvider: FC<{ children: ReactNode, scrollContainerRef: RefObject<HTMLElement>, scrollContentRef?: RefObject<HTMLElement> }> = function ScrollElementProvider({ children, scrollContainerRef, scrollContentRef }) {

  const value = useMemo(() => ({
    scrollContainerRef,
    scrollContentRef,
  }), [scrollContainerRef, scrollContentRef]);

  return (
    <ScrollElementContext.Provider value={value}>
      {children}
    </ScrollElementContext.Provider>
  );
};

export const useScrollElement = () => React.useContext(ScrollElementContext);
