import { useCallback, useEffect } from "react";
import {
  signInWithPopup as firebaseSignInWithPopup,
  signInWithRedirect as firebaseSignInWithRedirect,
  signInWithCredential as firebaseSignInWithCredential,
  getAdditionalUserInfo
} from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { useReactiveVar } from '@apollo/client';

import { firebaseAuth } from '../firebase';
import { captureException } from '@/utils/sentry';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { useSession } from "@/hooks/auth";
import { useSnackbar } from './snackbar';
import { error } from '../views/auth/utils/error';
import { landingTrackingVar } from "../vars/landing-tracking";


function reportError(description, message, code, error) {
  console.error(description, message, code, error);
  captureException(error);
}

export function useOauth({ provider, providerId }) {

  const { track } = useAnalyticsQueued();
  const { show } = useSnackbar();
  const { oAuthError } = useSession();

  let hasSessionStorage = false;
  try {
    const test = 'test';
    if(sessionStorage && sessionStorage.setItem && sessionStorage.getItem) {
      sessionStorage.setItem(test, test);
      if (sessionStorage.getItem(test) === test) {
        sessionStorage.removeItem(test);
        hasSessionStorage = true;
      }
    }
  } catch (e) {
    // ignore
  }

  const landingTracking = useReactiveVar(landingTrackingVar);
  let preferRedirect = false;
  if (!hasSessionStorage || landingTracking.probableInAppBrowser === 'facebook' || landingTracking.probableInAppBrowser === 'instagram') {
    preferRedirect = true;
  }

  useEffect(() => {
    if (oAuthError) {
      const [ userMessage, consoleError, doTrack ] = error(oAuthError);
      userMessage && show(userMessage);
      doTrack && track('auth_error', { category: 'auth', ...userMessage, code: oAuthError.code, message: oAuthError.message, providerId });
      consoleError && reportError(`${providerId} - oAuthError`, oAuthError.message, oAuthError.code, oAuthError);
    }
  }, [ oAuthError, show, providerId, track ]);

  const trackSuccess = useCallback((result) => {
    const userInfo = getAdditionalUserInfo(result);
    if (userInfo?.isNewUser) {
      track('signup', { category: 'auth', providerId });
    } else {
      track('login', { category: 'auth', providerId });
    }
    track(`${providerId}_oauth_signin`, { category: 'auth', providerId });
  }, [ track, providerId ]);

  const signInWithCredential = useCallback((credential) => {
    return firebaseSignInWithCredential(firebaseAuth, credential)
      .then((userCredential) => trackSuccess(userCredential)) // We should have successful auth
      .catch(err => {
        const [ userMessage, consoleError, doTrack ] = error(err);
        userMessage && show(userMessage);
        doTrack && track('auth_error', { category: 'auth', ...userMessage, code: err.code, message: err.message, providerId });
        consoleError && reportError(`${providerId} - signInWithCredential - native - error`, err.message, err.code, err);
      });
  } , [ providerId, show, trackSuccess, track ]);

  const signInWithRedirect = useCallback(() => {
    import('firebase/auth').then(({ setPersistence, browserSessionPersistence }) => {
      setPersistence(firebaseAuth, browserSessionPersistence);
      // since this is a redirect away from the app, results has to be handled when returning, auth.js -> getRedirectResult
      firebaseSignInWithRedirect(firebaseAuth, provider)
    })
    .catch(err => reportError(`${providerId} - firebaseSignInWithRedirect - error`, err.message, err.code, err));
  }, [ providerId, provider ]);

  const handlePopupError = useCallback((err) => {
    const [ userMessage, consoleError, doTrack ] = error(err);
    userMessage && show(userMessage);
    doTrack && track('auth_error', { category: 'auth', ...userMessage, code: err.code, message: err.message, providerId });
    consoleError && reportError(`${providerId} - signInWithPopup - error`, err.message, err.code, err);

    if (err.code === 'auth/popup-blocked' && !Capacitor.isNativePlatform()) {
      // Probably in-app browser, try redirect flow
      signInWithRedirect(provider);
    }
  }, [ show, track, signInWithRedirect, provider, providerId ]);

  const signInWithPopup = useCallback(() => {
    return firebaseSignInWithPopup(firebaseAuth, provider)
      .then((userCredential) => trackSuccess(userCredential)) // We should have successful auth
      .catch(err => handlePopupError(err, provider));
  }, [ provider, handlePopupError, trackSuccess ]);

  return { signInWithCredential, signInWithRedirect, signInWithPopup, handlePopupError, preferRedirect }
}
