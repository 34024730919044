import { useEffect, useState } from "react";
import { useParams } from "react-router";


// Used for preserving state during transitions, eg when navigating from canonical to feed, but not when navigating from canonical to canonical
export function useParamsDelayed<T extends ReturnType<typeof useParams>>(delayAfterUnset: number = 500) {
  const params = useParams<T>();

  const [delayedParams, setDelayedParams] = useState<T>(params);

  // modify all refs if one of the params changes, but not if all are unset
  const allUnset = Object.values(params).every(value => !value);
  const someChanged = Object.entries(params).some(([key, value]) => value !== delayedParams[key]);
  if(!allUnset && someChanged) {
    setDelayedParams(params);
  }

  // unset refs after transition
  useEffect(() => {
    if(Object.values(delayedParams).every(value => !value)) {
      const timeoutRef = setTimeout(() => {
        setDelayedParams(params);
      }, delayAfterUnset);
      return () => clearTimeout(timeoutRef);
    }
  }, [params, delayedParams, delayAfterUnset]);

  return delayedParams;
}
