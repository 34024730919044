import { useEffect, useRef, useState } from "react";
import { App as CapApp } from '@capacitor/app';
import { PluginListenerHandle } from "@capacitor/core";


export function useAppIsActive() {
  const [appIsActive, setAppIsActive] = useState(true);

  useEffect(() => {

    const handlePromises: Promise<PluginListenerHandle | void>[] = [];

    handlePromises.push(CapApp.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active?', isActive);

      if(appIsActive !== isActive) {
        setAppIsActive(isActive);
      }
    }).catch(e => console.error('CapApp.addListener error', e)));

    handlePromises.push(CapApp.addListener('appRestoredResult', data => {
      console.log('App restored state:', data);
    }).catch(e => console.error('CapApp.addListener error', e)));

    return () => {
      handlePromises.map(handlePromise => handlePromise.then(handle => handle?.remove()?.catch(e => console.error('CapApp remove listener error', e))));
    }
  }, [appIsActive]);

  return appIsActive;
}


export function useAppIsRestored(onRestored: () => void) {

  const appIsActive = useAppIsActive();
  const previousActiveState = useRef<boolean>(true);

  useEffect(() => {
    if(previousActiveState.current !== appIsActive) {
      previousActiveState.current = appIsActive;
      if (appIsActive) {
        console.log(`AppIsActive changed to active, refetching canonical queries`);
        onRestored()
      }
    }
  }, [appIsActive, previousActiveState, onRestored]);

  return appIsActive;
}
