import { useCallback, useEffect, useState } from "react";

import { useAnalyticsQueued } from './delicious-analytics';
import { isStorageAvailable, useStorage } from "./storage";


const updateListeners: (() => void)[] = [];
const TOURS = ['collections.info', 'explore.info', 'share.info', 'feed.release095', 'feed.friends010', 'friends.info', 'feed.info'] as const;
type TourType = typeof TOURS[number];

export function useStorageTour(tour: TourType): [boolean, () => void, () => void] {

  const { track } = useAnalyticsQueued();
  const { get, set, remove } = useStorage();

  const [show, setShow] = useState<boolean>(false);

  const key = `tour.${tour}`;

  const update = useCallback(() => {
    if(!isStorageAvailable()) {
      console.error('useStorageTour update - storage not available');
      return;
    }
    get(key).then((value) => {
      if (value === 'false') {
        setShow(false);
      } else if(value === 'true') {
        setShow(true);
      } else {
        setShow(true);
        set(key, 'true');
      }
    });
  }, [key, get, set]);

  useEffect(() => {
    update();
    updateListeners.push(update);
    return () => {
      const index = updateListeners.indexOf(update);
      if (index > -1) {
        updateListeners.splice(index, 1);
      }
    };
  }, [update]);


  const dismissTour = useCallback(() => {
    setShow(false);
    track('dismiss_tour', { category: 'tour', tour });

    if(!isStorageAvailable()) {
      console.error('useStorageTour dismissTour - storage not available');
      return;
    }
    set(key, 'false');
  }, [key, set, track, tour]);


  const resetTour = useCallback(() => {
    if(!isStorageAvailable()) {
      console.error('useStorageTour resetTour - storage not available');
      return;
    }

    TOURS.forEach((tour) => {
      const key = `tour.${tour}`;
      remove(key);
    });
    updateListeners.forEach((update) => update());

  }, [remove]);

  return [ show, dismissTour, resetTour ];
}
