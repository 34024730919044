import { useCallback, useEffect, useMemo, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Contacts, ContactPayload as CapContact, EmailType, PhoneType } from '@capacitor-community/contacts';
import md5 from 'crypto-js/md5';
import hex from 'crypto-js/enc-hex';
import fuzzysort from 'fuzzysort';

import { Contact, Person, useUpdateDeviceContactsMutation } from '../generated/graphql';
import { intersection } from '../utils/arrary';
import { GET_FOLLOWS } from '../queries/user';
import { isStorageAvailable, useStorage } from './storage';


export type DeviceContact = {
  contactId?: string,
  displayName: string,
  phoneNumbers?: Array<{
    label: string,
    number: string,
  }>,
  emails: Array<{
    label: string,
    address: string,
  }>,
  photoThumbnail?: string,
  organizationName?: string,
  organizationRole?: string,
  birthday?: string,
};


export async function readContacts(): Promise<DeviceContact[]> {
  function nativeToDeviceContact(c: CapContact): DeviceContact {
    const birthday = c.birthday ? `${c.birthday.year}-${c.birthday.month}-${c.birthday.day}` : undefined;
    return {
      contactId: c.contactId,
      displayName: c.name?.display || '',
      phoneNumbers: c.phones?.map(p => ({
        label: p.label || '',
        number: p.number || '',
      })) || [],
      emails: c.emails?.map(e => ({
        label: e.label || '',
        address: e.address || '',
      })) || [],
      photoThumbnail: c.image?.base64String || undefined,
      organizationName: c.organization?.company || '',
      organizationRole: c.organization?.jobTitle || '',
      birthday: birthday,
    };
  }

  if(Capacitor.getPlatform() === 'web') {
    return [
      // Test data for developing in browser
      // {contactId: '1', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Kalle Nilvér' }, emails: [{type: EmailType.Home, address: 'kalle@nilver.se'}], phones: []},
      // {contactId: '2', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Emil Kaiser' }, emails: [{type: EmailType.Home, address: 'emil@icecream.club'}], phones: []},
      // {contactId: '3', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Patrik Söderberg' }, emails: [{type: EmailType.Home, address: 'paso@paso.se'}], phones: [{type: PhoneType.Home, label: null, isPrimary: true, number: '0702-258476'}]},
      // {contactId: '4', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'David Karlsson' }, emails: [{type: EmailType.Home, address: 'david@booli.se'}], phones: []},
      // {contactId: '5', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'David Kulander' }, emails: [{type: EmailType.Home, address: 'david@sensebit.se'}], phones: []},
      // {contactId: '6', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Anonym Anonymsson' }, emails: [{type: EmailType.Home, address: 'anonymous@example.com'}], phones: []},
      // {contactId: '7', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Anne Anonymsson' }, emails: [{type: EmailType.Home, address: 'anne@example.com'}], phones: []},
      // {contactId: '8', name: { given: null, middle: null, family: null, prefix: null, suffix: null, display: 'Arne Anonymsson' }, emails: [{type: EmailType.Home, address: 'arne@example.com'}], phones: []},
    ].map(nativeToDeviceContact);
  }
  if(Capacitor.getPlatform() === 'android') {
    const permissionStatus = await Contacts.checkPermissions();
    if(permissionStatus.contacts === 'prompt') {
      const requestPermissionStatus = await Contacts.requestPermissions();
      if(requestPermissionStatus.contacts !== 'granted') {
        return [];
      }
    } else if(permissionStatus.contacts !== 'granted') {
      return [];
    }
  }

  try {
    const result = await Contacts.getContacts({
      projection: {
        // Specify which fields should be retrieved.
        name: true,
        organization: true,
        birthday: true,
        phones: true,
        emails: true,
        postalAddresses: true,
      }
    });
    return result.contacts.map(nativeToDeviceContact);
  } catch(e) {
    console.info('Users denied access to contacts');
    return [];
  }
}


function filterIncompleteDeviceContacts(deviceContacts: DeviceContact[]) {
  // filter out contacts missing required data
  const useableContacts = deviceContacts.filter(c => c.emails.length && c.displayName);
  return useableContacts;
}


function filterDeviceContactsNotFollowed(deviceContacts: DeviceContact[], contacts: Pick<Contact, 'emailHashes'>[], user?: Pick<Person, 'emails'>) {
  const contactEmailHashes = contacts.map(c => c.emailHashes).flat(1);
  const deviceContactsWithFlatEmails = deviceContacts.map(dc => ({ ...dc, flatEmails: dc.emails.map(e => e.address) }) );

  // Filter out contact suggestions with email we already have
  const deviceContactsWithoutSelf = deviceContactsWithFlatEmails.filter(dc => intersection(dc.flatEmails, user?.emails || []).length === 0);
  const deviceContactsWithHashes = deviceContactsWithoutSelf.map(dc => ({...dc, emailHashes: dc.flatEmails.map(email => email ? hex.stringify(md5(email)) : null) }) );

  const deviceContactsWithOnlyNewEmails = deviceContactsWithHashes.filter(dc => intersection(dc.emailHashes, contactEmailHashes).length === 0);
  return deviceContactsWithOnlyNewEmails;
}


function filterDeviceContacts(deviceContacts: DeviceContact[], filter: string) {
  const contactsWithSearchableEmails = deviceContacts.map(dc => ({ ...dc, emailsJoined: dc.emails.join(' ') }) );
  const results = fuzzysort.go(filter, contactsWithSearchableEmails, {
    keys: ['displayName', 'emailsJoined'],
    limit: 5,
  });
  return results.map(result => result.obj);
}


export function useDeviceContacts(contacts: Pick<Contact, 'emailHashes'>[] | undefined, filter: string, user: Pick<Person, 'emails'>, followSuggestions: Contact[] | undefined = undefined) {

  const [allDeviceContacts, setAllDeviceContacts] = useState<DeviceContact[]>([]);
  const [usableDeviceContacts, setUsableDeviceContacts] = useState<DeviceContact[]>([]);
  const [filteredDeviceContacts, setFilteredDeviceContacts] = useState<DeviceContact[]>([]);
  const [hasImportedDeviceContacts, setHasImportedDeviceContacts] = useState<boolean>(false);

  const storageKey = 'sentDeviceContactsHash';
  const { get, set } = useStorage();

  const [updateDeviceContacts, { loading }] = useUpdateDeviceContactsMutation();

  const importData = useCallback(() => {
    return readContacts().then(deviceContacts => {
      const emails = deviceContacts.map(dc => dc.emails.map(e => e.address)).flat().sort();
      const deviceContactsHash = hex.stringify(md5(emails.join('')));

      if(!isStorageAvailable()) {
        console.error('useDeviceContacts: Storage not available');
        return;
      }

      get(storageKey).then(sentDeviceContactsHash => {

        if(deviceContacts.length && sentDeviceContactsHash !== deviceContactsHash && !loading) {
          updateDeviceContacts({
            variables: { input: { contacts: deviceContacts } },
            refetchQueries: [{ query: GET_FOLLOWS }],
          }).then(() => {
            set(storageKey, deviceContactsHash).catch(err => console.error(`Error setting sentDeviceContactsHash: ${err}`));
          }).catch(err => console.error(`Error updating device contacts: ${err}`));
        }
      }).catch(err => console.error(`Error getting sentDeviceContactsHash: ${err}`));

      setAllDeviceContacts(deviceContacts);
      setHasImportedDeviceContacts(true);
    }).catch(e => {
      console.error('Could not import device contacts', e);
    });
  }, [get, loading, updateDeviceContacts, set]);

  useEffect(() => {
    if(contacts) {
      const notIncomplete = filterIncompleteDeviceContacts(allDeviceContacts);
      const notFollowed = filterDeviceContactsNotFollowed(notIncomplete, contacts, user);
      setUsableDeviceContacts(notFollowed);
    }
  }, [contacts, user, allDeviceContacts]);

  useEffect(() => {
    if(hasImportedDeviceContacts) {
      if (filter) {
        const filtered = filterDeviceContacts(usableDeviceContacts, filter);
        setFilteredDeviceContacts(filtered);
      } else {
        setFilteredDeviceContacts(usableDeviceContacts);
      }
    }
  }, [filter, usableDeviceContacts, hasImportedDeviceContacts]);


  const filteredFollowSuggestions: Contact[] = useMemo(() => {

    if(filter && followSuggestions) {
      return fuzzysort.go(filter, followSuggestions, {
        keys: ['name', 'username'],
        limit: 10,
      }).map(result => result.obj);
    }
    return followSuggestions || [];
  }, [filter, followSuggestions]);


  useEffect(() => {
    // import contacts into local state on first render if we previously have loaded and saved contacts
    if(!hasImportedDeviceContacts && !loading) {
      get(storageKey).then(sentDeviceContactsHash => {
        if(sentDeviceContactsHash) {
          importData();
        }
      }).catch(err => console.error(`Error getting sentDeviceContactsHash: ${err}`));
    }
  }, [importData, hasImportedDeviceContacts, loading, get]);


  return {
    filteredDeviceContacts,
    usableDeviceContacts,
    allDeviceContacts,
    hasImportedDeviceContacts,
    requestPermissions: importData,

    followSuggestions,
    filteredFollowSuggestions,
  };
}
