import { useState, useEffect, useCallback } from "react";
import { Capacitor } from '@capacitor/core';
import { App as CapApp } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { AppLauncher } from '@capacitor/app-launcher';
import { useQuery } from '@apollo/client';

import { LATEST_BUILD } from '../queries/app';
import { useAnalyticsQueued } from "./delicious-analytics";

export function useShouldUpdate() {

  const [ shouldUpdate, setShouldUpdate ] = useState(false);
  const [ updateLater , setUpdateLater ] = useState(false);

  const { data } = useQuery(LATEST_BUILD, {
    fetchPolicy: "no-cache",
    variables: {
      platform: Capacitor.getPlatform()
    },
  });
  const latestBuild = data?.latestBuild;
  const { track } = useAnalyticsQueued()

  useEffect(() => {
    if (latestBuild && Capacitor.isNativePlatform() && !updateLater) {
      CapApp.getInfo().then(({ build }) => {
        console.log(`Build number, client: ${build} server: ${latestBuild}`);
        track('should_update_app', { category: 'system', clientBuild: build, serverBuild: latestBuild });
        if (parseInt(latestBuild) > parseInt(build)) {
          track('should_update_app', { category: 'system', clientBuild: build, serverBuild: latestBuild });
          setShouldUpdate(true);
        }
      });
    }
  }, [ latestBuild, updateLater, track ]);

  const showShouldUpdate = useCallback(async () => {
    try {
      const { value: willUpdate } = await Dialog.confirm({
        title: 'New version available',
        message: `New things await you, would you like to update the app?`,
        okButtonTitle: 'Update',
        cancelButtonTitle: 'Later'
      });
      if (willUpdate) {
        let url = 'https://icecream.club';
        if (Capacitor.getPlatform() === 'ios') {
          const { value: canOpen } = await AppLauncher.canOpenUrl({ url: 'itms-apps://' });
          url = canOpen ? 'itms-apps://apps.apple.com/us/app/ice-cream-club/id1565743981' : 'https://apps.apple.com/app/apple-store/id1565743981?pt=122551086&ct=should-update&mt=8';
        } else {
          url = 'https://play.google.com/store/apps/details?id=club.icecream&utm_source=icc&utm_medium=web&utm_campaign=should_update';
        }
        AppLauncher.openUrl({ url: url });
      } else {
        // "Update later"
        track('should_update_app_later_requested', { category: 'system' });
        setUpdateLater(true);
      }
    } catch (e) {
      console.error('showShouldUpdate', e);
    }
  }, [ track ]);

  return [ shouldUpdate, showShouldUpdate ];
}
