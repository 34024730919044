export function useTap(config = {}) {

  const { taps = 2, touches = 1, interval = 300 } = config;

  let lastTap = 0;
  let tapsCount = 0;

  const handler = (onTap) => {
    return ev => {
      if (ev.touches?.length === touches) {
        const now = Date.now();
        if (now - lastTap < interval) {
          tapsCount++;
        } else {
          tapsCount = 1;
        }
        lastTap = now;
        if (tapsCount === taps) {
          tapsCount = 0;
          onTap(ev);
        }
      }
    };
  };

  return handler;
}
