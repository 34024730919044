import { useEffect, useCallback, useRef, ReactNode } from "react";
import { Capacitor } from "@capacitor/core";
import { Button, Alert, Typography, Zoom, AlertProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar as useNotistack, SnackbarContent } from 'notistack';
import { useHistory } from "react-router-dom";

import { CanonicalContent, Message, Share } from "@/generated/graphql";
import { CheckCircleFilled } from "../components/icons/CheckCircleFilled";
import { AddCircleFilled } from "../components/icons/AddCircleFilled";
import { absolutize } from "../utils/url";
import { canonicalLink } from "../utils/routes";


export type ShowArgs = {
  text: string,
  actionText?: string,
  action?: ()=>void,
  icon?: ReactNode,
  severity?: AlertProps['severity']
};


export function useSnackbar(share?: Pick<Share, '_id'> & { message?: Pick<Message, 'link'> | null } | null, canonicalContent?: Pick<CanonicalContent, '_id'> | null) {

  const { enqueueSnackbar, closeSnackbar } = useNotistack();
  const theme = useTheme();

  const history = useHistory();
  const mounted = useRef(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (mounted.current) {
        closeSnackbar();
      } else {
        mounted.current = true;
      }
    });

    return () => {
      closeSnackbar();
      unlisten();
    };
  }, [ history, closeSnackbar ]);

  const gotoShareForm = () => {
    const link = share?.message?.link ? share.message.link : absolutize(canonicalLink(canonicalContent?._id));
    history.push(`/share?share-url=${encodeURIComponent(link)}&hidden=link_lists`);
  };

  const show = useCallback(({ text, actionText, action, icon, severity='info' }: ShowArgs) => {
    const AlertProps = severity === 'error' ? {
      variant: 'outlined' as const,
      sx: {
        backgroundColor: 'background.paper',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '& .MuiAlert-icon': { pt: '10px' },
        mt: Capacitor.getPlatform() === 'ios' ? 4 : 0,
        width: '100%',
      }
    } : {
      variant: 'filled' as const,
      sx: {
        color: "secondary",
        '& .MuiAlert-icon': { pt: '10px' },
        mt: Capacitor.getPlatform() === 'ios' ? 4 : 0,
        width: '100%',
      }
    };

    enqueueSnackbar(text, {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      TransitionComponent: Zoom,
      content: (key, message) => (
        <SnackbarContent key={key}>
          <Alert
            key={key}
            {...AlertProps}
            severity={severity}
            icon={icon}
            action={actionText && action && (
              <Button onClick={() => {
                action();
                closeSnackbar();
              }}>
                {actionText}
              </Button>
            )}
          >
            <Typography variant="body1">{message}</Typography>
          </Alert>
        </SnackbarContent>
      ),
    });
  }, [ enqueueSnackbar, closeSnackbar, theme ]);

  return {
    watchedSnackbar: () => show({ text: 'Marked as seen', actionText: 'Share', action: gotoShareForm, icon: <CheckCircleFilled /> }),
    watchlistSnackbar: (cb: ()=>void) => show({ text: 'Added', actionText: 'Add to collection', action: cb, icon: <AddCircleFilled /> }),
    closeSnackbar,
    show,
  };
}
