import { Toast } from '@capacitor/toast';
import { useCallback } from 'react';

import { useAddContactMutation, useUpdateGroupMutation } from "../generated/graphql";
import { useSession } from './auth';
import { useAnalyticsQueued } from "./delicious-analytics";


export function useInvite(from: string, onComplete: () => void, group?: { _id: string }) {

  const { refetch } = useSession();
  const { track } = useAnalyticsQueued();

  const [updateGroup, { loading: updateGroupLoading, error: updateGroupError }] = useUpdateGroupMutation({
    onCompleted() {
      refetch();
      onComplete();
    }
  });

  const [addContact, { loading: addContactLoading, error: addContactError }] = useAddContactMutation({
    onCompleted() {
      refetch();
      onComplete();
    }
  });

  const inviteEmail = useCallback((email: string) => {
    track(`email_invite_${from}`, { category: 'invite' });
    if(group) {
      updateGroup({
        variables: {
          input: {
            groupId: group._id,
            addMemberEmail: email,
          }
        }
      });
      Toast.show({ text: "Friend added to group", position: 'top' });
    } else {
      addContact({
        variables: {
          email: email
        }
      });
      Toast.show({ text: "Friend invited", position: 'top' });
    }
  }, [group, from, updateGroup, addContact, track]);

  if(updateGroupError) {
    console.error(updateGroupError);
  }
  if(addContactError) {
    console.error(addContactError);
  }

  return { inviteEmail, loading: updateGroupLoading || addContactLoading };
}
