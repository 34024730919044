import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';

import { BookmarkProvider } from './bookmarks-context';


// Use useLayoutEffect only on the client
export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export function DataProvider({ children }) {
  return (
    <BookmarkProvider>
      {children}
    </BookmarkProvider>
  );
}

DataProvider.propTypes = {
  children: PropTypes.any,
}
