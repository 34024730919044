import { Preferences } from '@capacitor/preferences';


let _isStorageAvailable: boolean|undefined = undefined;

Preferences.set({ key: '__isStorageAvailable', value: '__isStorageAvailable' }).then(() =>
Preferences.get({ key: '__isStorageAvailable' })
).then(({ value }) => {
  if (value === '__isStorageAvailable') {
    _isStorageAvailable = true;
  } else {
    _isStorageAvailable = false;
  }
}).catch(() => {
  _isStorageAvailable = false;
});

// undefined means we don't know yet as storage is async
export function isStorageAvailable() { return _isStorageAvailable; }

const storage = {
  get: async (key: string) => {
    return Preferences.get({ key }).then(result => result.value);
  },
  set: async (key: string, value: string) => {
    return Preferences.set({ key, value });
  },
  remove: async (key: string) => {
    return Preferences.remove({ key });
  },
  isStorageAvailable: (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      function poll() {
        attempts++;
        if (attempts > 100) {
          reject('isStorageAvailable polling timed out');
          return;
        }
        const isAvailable = isStorageAvailable();
        if(isAvailable === undefined) {
          setTimeout(poll, 50);
        } else {
          resolve(isAvailable);
        }
      }
      poll();
    });
  },
};


export function useStorage() {
  return storage;
}

export {
  Preferences,
}
