import { useEffect, useState } from "react";
import { useSession } from "./auth";


export function useIsRole(role) {

  const { auth } = useSession();

  const [isRole, setIsRole] = useState(undefined);

  useEffect(() => {
    if(auth) {
      auth.getIdTokenResult().then(({ claims }) => {
        setIsRole(claims.roles?.includes(role));
      });
    }
  }, [role, auth]);

  return isRole;
}
