import { MutableRefObject, RefObject, useEffect, useRef } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useHistory } from "react-router";

declare type ElementRef = MutableRefObject<HTMLElement | undefined>;

export function useScrollTrigger(effect: (scrollY: number, first: boolean) => void, trigger: number, scrollContainerRef: ElementRef, scrollContentRef: ElementRef) {

  const firstTriggerRef = useRef(true);

  useScrollPosition(({ currPos }) => {
    if(currPos.y > trigger) {
      effect(currPos.y, firstTriggerRef.current);
      firstTriggerRef.current = false;
    }
  }, undefined, scrollContentRef, false, 100, scrollContainerRef);

  return;
}


const onScroll = (ev: Event) => {
  const node = ev.currentTarget as HTMLElement;

  const first = node.getAttribute('data-scroll-first');
  const previous = node.getAttribute('data-scroll-previous');
  const last = node.scrollTop;

  const scrollDownThreshold = 200;
  const scrollUpThreshold = 100;

  if(!first || !previous) {
    // first scroll
    node.setAttribute('data-scroll-first', last.toString());
    node.setAttribute('data-scroll-previous', last.toString());
  } else {
    const changeDirectionDownUp = last > parseInt(first) && last < parseInt(previous);
    const changeDirectionUpDown = last < parseInt(first) && last > parseInt(previous);
    const scrollDistance = last - parseInt(first);

    if(changeDirectionDownUp || changeDirectionUpDown) {
      node.setAttribute('data-scroll-first', last.toString());
    } else if(scrollDistance > scrollDownThreshold) {
      document.body.classList.add('scroll-down');
      document.body.classList.remove('scroll-up');
    } else if(scrollDistance < -scrollUpThreshold) {
      document.body.classList.add('scroll-up');
      document.body.classList.remove('scroll-down');
    }

    node.setAttribute('data-scroll-previous', last.toString());
  }
};


export function useScrollUpDown(scrollContainerRef: RefObject<HTMLElement>, isVisible=true) {

  const prevIsVisibleRef = useRef(isVisible);

  const history = useHistory();

  useEffect(() => {
    const node = scrollContainerRef.current;
    if(node) {
      if(isVisible) {
        node.addEventListener('scroll', onScroll);
        return () => {
          node.removeEventListener('scroll', onScroll);
        };
      } else if(!isVisible && prevIsVisibleRef.current) {
        node.removeAttribute('data-scroll-first');
        node.removeAttribute('data-scroll-previous');
        document.body.classList.remove('scroll-up');
        document.body.classList.remove('scroll-down');
      }
    }

    prevIsVisibleRef.current = isVisible;
    return () => {
      document.body.classList.remove('scroll-up');
      document.body.classList.remove('scroll-down');
    };
  }, [scrollContainerRef, isVisible]);

  // reset scroll position when location changes
  useEffect(() => {
    const unlisten = history.listen(() => {
      const node = scrollContainerRef.current;
      if(node) {
        node.removeAttribute('data-scroll-first');
        node.removeAttribute('data-scroll-previous');
      }
      document.body.classList.remove('scroll-up');
      document.body.classList.remove('scroll-down');
    });

    return unlisten;
  }, [history, scrollContainerRef]);

}
