import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import throttle from "lodash/throttle";


export type Orientation = {
  event: DeviceOrientationEvent | null;
  alpha: number | null;
  beta: number | null;
  gamma: number | null;
  calculated: 'portrait' | 'landscape-left' | 'landscape-right' | 'unknown';
}


export type IOSDeviceOrientationEvent = DeviceOrientationEvent & {
  requestPermission: () => Promise<PermissionState>;
};

export function isIOSDeviceOrientationEvent(event: DeviceOrientationEvent): event is IOSDeviceOrientationEvent {
  return 'requestPermission' in event && typeof event['requestPermission'] === 'function';
}


export function useOrientation(onlyCalculated: boolean = true): [Orientation | null, () => void, PermissionState | null] {

  const isListening = useRef(false);
  const [permissionState, setPermissionState] = useState<PermissionState | null>(null);
  const [orientation, setOrientation] = useState<Orientation | null>(null);
  // used in callback to prevent unnecessary re-renders when onlyCalculated is true
  const calculatedRef = useRef<Orientation['calculated']>('unknown');

  const handleOrientation = useCallback(function (event: DeviceOrientationEvent) {
    const beta = event.beta || 0;
    const gamma = event.gamma || 0;

    let estimatedOrientation: Orientation['calculated'] = "unknown";

    if (Math.abs(gamma) < 40 && Math.abs(beta) > 50) {
      // Gamma near 0, the device is upright (portrait mode)
      estimatedOrientation = "portrait";
    } else if (Math.abs(beta) < 40) {
      // Gamma near ±90, the device is sideways (landscape mode)
      if (gamma > 50) {
        estimatedOrientation = "landscape-right";
      } else if (gamma < -50) {
        estimatedOrientation = "landscape-left";
      }
    }

    if (!onlyCalculated || calculatedRef.current !== estimatedOrientation) {
      setOrientation({
        event,
        alpha: event.alpha,
        beta: event.beta,
        gamma: event.gamma,
        calculated: estimatedOrientation,
      });
    }
  }, [onlyCalculated]);

  // eslint-disable-next-line react-compiler/react-compiler
  const throttledOrientation = useMemo(() => throttle(handleOrientation, 250), [handleOrientation]);


  const requestPermission = useCallback(() => {
    // console.log('DeviceOrientationEvent.requestPermission');
    if(isListening.current) {
      // console.log('DeviceOrientationEvent.requestPermission already listening');
      return;
    }
    const doe = window.DeviceOrientationEvent as unknown as DeviceOrientationEvent;
    if (isIOSDeviceOrientationEvent(doe)) {
      doe.requestPermission().then(permissionState => {
        // console.log('DeviceOrientationEvent.requestPermission', permissionState);
        setPermissionState(permissionState);
        if (permissionState === 'granted') {
          // console.log('DeviceOrientationEvent.requestPermission addEventListener');
          window.addEventListener("deviceorientation", throttledOrientation);
          isListening.current = true;
        }
      });
    } else {
      // console.log('DeviceOrientationEvent.requestPermission addEventListener');
      window.addEventListener("deviceorientation", throttledOrientation);
      isListening.current = true;
    }
  }, [throttledOrientation]);


  useEffect(() => {
    return () => window.removeEventListener("deviceorientation", throttledOrientation);
  }, [throttledOrientation]);


  return [orientation, requestPermission, permissionState];
}
