import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { App as CapApp } from '@capacitor/app';
import { subMinutes } from 'date-fns';
import { useMutation, useReactiveVar } from '@apollo/client';
import { StringParam, useQueryParam } from "use-query-params";
import { v4 as uuidv4 } from 'uuid';
import { Capacitor } from '@capacitor/core';

import { hasLandingTracking, landingTrackingVar, setLandingTrackingVar } from '../vars/landing-tracking';
import { locationWithMetaVar } from '../vars/location-meta';
import { locationCountVar } from '../vars/location-count';
import { UPDATE_USER } from '../queries/user';
import { getProbableInAppBrowser } from '../utils/in-app';
import { useSession } from './auth';
import { isStorageAvailable, useStorage } from './storage';
import { useAnalyticsQueued } from './delicious-analytics';


export const HistoryStackContext = createContext();

export function useHistoryStackContext() {
  return useContext(HistoryStackContext);
}

export const HistoryStackProvider = ({ children }) => {
  const history = useHistory();

  const stackRef = useRef([history.location])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // console.log('history.listen', location, action, stackRef);
      if(action === 'POP') {
        stackRef.current = stackRef.current.slice(0, -1);
      } else if(action === 'PUSH') {
        stackRef.current = [...stackRef.current, location];
      } else if(action === 'REPLACE') {
        stackRef.current[stackRef.current.length-1] = location;
      }
    });

    return unlisten;
  }, [history]);

  return <HistoryStackContext.Provider value={stackRef}>{children}</HistoryStackContext.Provider>;
}

HistoryStackProvider.propTypes = {
  children: PropTypes.node,
};


export function useNavigationActions() {
  const history = useHistory();
  const historyStack = useHistoryStackContext();
  const { track } = useAnalyticsQueued();

  const goBackOrHome = useCallback(function goBackOrHome() {
    const hasPreviousPages = historyStack.current.length > 1;

    console.log('goBackOrHome', { location: history.location, hasPreviousPages });
    track('navigate_back', { category: 'navigation', hasPreviousPages, location: history.location.pathname });
    if (hasPreviousPages) {
      history.goBack();
      return false;
    } else if(!['/', '/feed', '/auth/login'].includes(history.location.pathname)) {
      history.replace(`/`);
    } else {
      CapApp.exitApp();
    }
  }, [history, historyStack, track]);

  const closeSheet = useCallback(function closeSheet(urlParam) {
    const hasPreviousPages = historyStack.current.length > 1;
    const previousPage = hasPreviousPages && historyStack.current[historyStack.current.length-2];

    const urlParams = new URLSearchParams(history.location.search);
    urlParams.delete(urlParam);

    if(!hasPreviousPages) {
      history.replace({ search: urlParams.toString() });
    } else if(previousPage.pathname === history.location.pathname) {
      history.goBack();
    } else {
      history.replace({ search: urlParams.toString() });
    }
  }, [history, historyStack]);

  const goBack = useCallback(function goBack(ev) {
    if(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    track('navigate_back', { category: 'navigation', hasPreviousPages: historyStack.current.length > 1, location: history.location.pathname });
    history.goBack();
    return false;
  }, [history, historyStack, track]);

  return {
    goBack,
    goBackOrHome,
    hasPreviousPages: historyStack.current.length > 1,
    closeSheet,
  };
}


export function useInitializeLocationWithMeta() {
  const history = useHistory();

  // initial location
  useEffect(() => {
    locationWithMetaVar({ location: history.location, action: 'PUSH', timestamp: Date.now() });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // location updates
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      locationWithMetaVar({ location, action, timestamp: Date.now() });
    });
    return unlisten;
  }, [history]);
}


export function useLocationWithMeta() {
  return useReactiveVar(locationWithMetaVar);
}


export function useInitializeLocationCount() {
  const history = useHistory();

  const prevPathRef = useRef(history.location.pathname);

  // location updates
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const before = locationCountVar();
      if(location.pathname === prevPathRef.current) {
        locationCountVar({ ...before, samePath: before.samePath + 1 });
      } else {
        locationCountVar({ ...before, [action.toLowerCase()]: before[action.toLowerCase()] + 1 });
      }
      prevPathRef.current = location.pathname;
    });
    return unlisten;
  }, [history]);
}


export function useLocationCount() {
  return useReactiveVar(locationCountVar);
}


export function useReferrer() {

  const location = useLocation();
  const { user } = useSession();

  // we need to store iat in case it is lost from the query params in some navigation
  const { get, set } = useStorage();
  // keep iat in the url so navigation from in app browser to real browser has same id
  const [inAppToken, setInAppToken] = useQueryParam('iat', StringParam);
  const probableInAppBrowser = getProbableInAppBrowser(typeof window === 'undefined' ? '' : window.navigator.userAgent);

  const [ updateUser, { loading: updateUserLoading } ] = useMutation(UPDATE_USER);

  const landingTracking = useReactiveVar(landingTrackingVar);

  const addIat = useCallback(async () => {
    if(!isStorageAvailable()) {
      console.error('useReferrer: Storage not available, cannot add iat to URL')
      return;
    }
    let iat = await get('iat').catch(err => console.warn('Error fetching iat from storage', err));
    if(!iat) {
      iat = uuidv4();
      set('iat', iat).catch(err => console.warn('Error setting iat to storage', err));
    }
    setInAppToken(iat, 'replaceIn');
  }, [ get, set, setInAppToken ]);

  useEffect(() => {
    // in-app token will be available on location
    setLandingTrackingVar(location);

    if(probableInAppBrowser && !inAppToken) {
      // add a token to the URL when we're in an in-app browser
      addIat().catch(console.error);
    } else if(!probableInAppBrowser && inAppToken) {
      // remove it from the URL when we have escaped the in-app browser
      setInAppToken(undefined, 'replaceIn');
    }
  }, [ location, inAppToken, setInAppToken, probableInAppBrowser, addIat ]);

  useEffect(() => {
    if(user && !updateUserLoading) {
      const userJustCreated = !user.activatedAt || new Date(user.activatedAt) > subMinutes(new Date(), 15);
      if(hasLandingTracking() && userJustCreated && !user.hasFirstReferrer) {
        updateUser({ variables: { input: { firstReferrer: landingTracking } } })
          .catch(err => {
            console.error('Update firstReferrer error', err);
          });
      }
    }
  }, [ user, landingTracking, updateUser, updateUserLoading ]);

  return landingTracking;
}


export function useOverrideBackButton() {
  const history = useHistory();
  const { track } = useAnalyticsQueued();

  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      const listenerHandlePromise = CapApp.addListener('backButton', ({ canGoBack }) => {
        track('android_back_button', { category: 'history', canGoBack, location: history.location.pathname });
        if (canGoBack) {
          history.goBack();
          return false;
        } else if(history.location.pathname && !['/feed', '/', '/login', '/auth/login', '/auth/signup'].includes(history.location.pathname)) {
          history.replace(`/`);
        } else {
          CapApp.exitApp();
        }
      }).catch(e => console.error('CapApp.addListener backButton error', e));
      return () => listenerHandlePromise.then(listenerHandle => listenerHandle.remove().catch(e => console.error('CapApp remove backButton listener error', e)));
    }
  }, [history, track]);
}
