import { useEffect, useCallback, useState } from 'react';
import { Capacitor, PermissionState } from '@capacitor/core';
import { FirebaseMessaging, NotificationActionPerformedEvent, NotificationReceivedEvent } from '@capacitor-firebase/messaging';
import { useDebounce } from 'use-debounce';

import { Channel, useAddPushSubscriptionMutation, useRemovePushSubscriptionMutation } from '../generated/graphql';
import { useSession } from './auth';
import { useAnalyticsQueued } from './delicious-analytics';
import { isStorageAvailable, useStorage } from './storage';


export type { NotificationActionPerformedEvent, NotificationReceivedEvent };

export function usePushNotifications() {

  const { user, refetch } = useSession();
  const { track } = useAnalyticsQueued();
  const { remove, set, get, isStorageAvailable: isStorageAvailableAsync } = useStorage();

  const [ addPushSubscription, { error: serverError, loading } ] = useAddPushSubscriptionMutation({ onCompleted: refetch });
  const [ removePushSubscription ] = useRemovePushSubscriptionMutation({ onCompleted: refetch });

  const [ isPushEnabled, setIsPushEnabled ] = useState<{ enabled: boolean | null, reason: string | null}>({ enabled: null, reason: null });
  const [ debouncedIsPushEnabled ] = useDebounce(isPushEnabled, 200);

  const [ fcmTokenFromNative, setNativeToken ] = useState('');
  const [ error, setError ] = useState('');

  const [ fcmTokenFromCache, setFcmTokenFromCache ] = useState<string | null>('before-cache-get');

  const getToken = useCallback(() => {
    if (Capacitor.isNativePlatform()) {
      return FirebaseMessaging.getToken({}).then(({ token }) => {
        if (token) {
          setNativeToken(token);
        } else {
          setError('no-token');
        }
        return token;
      }).catch(e => {
        setError(e);
        console.error('usePushNotifications getToken', e);
      });
    }
    return null;
  }, []);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const togglePush = useCallback(() => {

    const subscribe = () => {
      let enabled = false;
      let reason: PermissionState | null = null;
      return FirebaseMessaging.requestPermissions().then(({ receive }) => {
        reason = receive;
        if (receive === 'granted') {
          return getToken();
        }
        return null;
      }).then((token) => {
        if (token) {
          addPushSubscription({ variables: { type: Capacitor.getPlatform(), channelId: token, payload: token } });
          enabled = true;
        }
        return { enabled, reason };
      }).catch(e => {
        setError(e);
        console.error('usePushNotifications togglePush subscribe', e);
        return { enabled, reason };
      });
    };

    if(debouncedIsPushEnabled.enabled) {
      console.log('Push - Unsubscribe', fcmTokenFromNative);
      removePushSubscription({ variables: { channelId: fcmTokenFromNative } });
      if(isStorageAvailable()) {
        remove('fcmToken');
      } else {
        console.warn('Push - Storage not available for fcmToken removal');
      }
      track('toggle_push', {category: 'user-settings', label: false});
      setIsPushEnabled({ enabled: false, reason: 'unsubscribe'});
    } else {
      console.log('Push - Subscribe', fcmTokenFromNative);
      subscribe().then(({ enabled, reason }) => {
        track('toggle_push', {category: 'user-settings', label: enabled, reason});
        setIsPushEnabled({ enabled: !!enabled, reason});
      }).catch(e => {
        console.error(`Push - Error from subscribe ${e}`);
        track('toggle_push', {category: 'user-settings', label: false, reason: e.message});
        setIsPushEnabled({ enabled: false, reason: 'error'});
      });
    }
  }, [debouncedIsPushEnabled, setIsPushEnabled, fcmTokenFromNative, addPushSubscription, removePushSubscription, track, remove, getToken]);


  useEffect(() => {

    isStorageAvailableAsync().then((isAvailable) => {
      if(isAvailable) {
        get('fcmToken').then((value) => {
          setFcmTokenFromCache(value);
        }).catch(e => console.error("get('fcmToken')", e));
      } else {
        console.warn('Push - Storage not available for fcmToken');
      }
    }).catch(e => console.error('usePushNotifications setup failed - isStorageAvailableAsync rejected', e));

  }, [get, isStorageAvailableAsync]);

  useEffect(() => {
    if(Capacitor.isNativePlatform()) {
      if(user?.channels && fcmTokenFromNative && !serverError && !error && !loading && fcmTokenFromCache !== 'before-cache-get') {
        const channels: Channel[] = user.channels;
        FirebaseMessaging.checkPermissions().then(({ receive }) => {
          if (receive === 'granted') {

            const serverMatchesNative = channels.find( ({channelId}) => channelId === fcmTokenFromNative)?.channelId;

            const serverMatchesCache = channels.find( ({channelId}) => channelId === fcmTokenFromCache)?.channelId;

            if (serverMatchesNative) {
              console.log('Push - Tokens match');
              setIsPushEnabled({ enabled: true, reason: 'token-match' });
              if(fcmTokenFromCache && serverMatchesCache && fcmTokenFromNative !== fcmTokenFromCache) {
                console.log('Push - Remove old cached token');
                removePushSubscription({ variables: { channelId: fcmTokenFromCache } });
                if(isStorageAvailable()) {
                  set('fcmToken', fcmTokenFromNative);
                } else {
                  console.warn('Push - Storage not available for fcmToken update');
                }
              }
            } else if (!serverMatchesNative) {
              console.log('Push - Update server with new fcmToken');
              // when user.channels is updated the useEffect will run again and enable push
              setIsPushEnabled({ enabled: false, reason: 'updating-token' });
              if(serverMatchesCache) {
                addPushSubscription({ variables: { type: Capacitor.getPlatform(), channelId: fcmTokenFromNative, payload: fcmTokenFromNative } }).then(() => {
                  console.log("Push - Remove old token from server");
                  if(isStorageAvailable()) {
                    set('fcmToken', fcmTokenFromNative);
                  } else {
                    console.warn('Push - Storage not available for fcmToken update');
                  }
                  if(fcmTokenFromCache) {
                    // remove old token from server after adding new token
                    removePushSubscription({ variables: { channelId: fcmTokenFromCache } });
                  }
                });
              } else if(!serverMatchesCache) {
                addPushSubscription({ variables: { type: Capacitor.getPlatform(), channelId: fcmTokenFromNative, payload: fcmTokenFromNative } });
                if(isStorageAvailable()) {
                  set('fcmToken', fcmTokenFromNative);
                } else {
                  console.warn('Push - Storage not available for fcmToken update');
                }
              }
            }

          } else if (receive === 'denied') {
            console.error('Push - Permissions denied');
            setIsPushEnabled({ enabled: false, reason: 'denied' });
          } else if (receive === 'prompt') {
            setIsPushEnabled({ enabled: false, reason: 'prompt' });
          } else {
            console.error(`Push - Permissions disabled, received: ${receive}, fcmTokenFromCache: ${JSON.stringify(fcmTokenFromCache)}, fcmTokenFromNative: ${JSON.stringify(fcmTokenFromNative)}`);
            setIsPushEnabled({ enabled: false, reason: 'unknown' });
          }
        }).catch(e => {
          console.error(`Push - Error from FirebaseMessaging.checkPermissions`, e);
          setIsPushEnabled({ enabled: false, reason: 'error' });
        });
      } else if (error) {
        console.error('Push - Error from registration', error);
        setIsPushEnabled({ enabled: false, reason: 'error' });
      }
    } else {
      setIsPushEnabled({ enabled: false, reason: 'web' });
    }

  }, [ user?.channels, fcmTokenFromNative, error, serverError, loading, fcmTokenFromCache, set, addPushSubscription, removePushSubscription ]);

  return { isPushEnabled: debouncedIsPushEnabled, token: fcmTokenFromNative, togglePush };
}


export function usePushNotificationEvents () {

  const [ received, setReceived ] = useState<NotificationReceivedEvent>();
  const [ action, setAction ] = useState<NotificationActionPerformedEvent>();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const eventHandlePromise = FirebaseMessaging.addListener('notificationReceived', (value) => {
        console.log('usePushEvents EVENT', 'notificationReceived', value);
        setReceived(value);
      }).catch(e => console.error('usePushEvents event', e));
      return () => {
        eventHandlePromise.then((eventHandle) => eventHandle?.remove()).catch(e => console.error('remove usePushEvents event', e));
      }
    }
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const eventHandlePromise = FirebaseMessaging.addListener('notificationActionPerformed', (value) => {
        console.log('usePushEvents EVENT', 'notificationActionPerformed', value);
        setAction(value);
      }).catch(e => console.error('usePushEvents event', e));
      return () => {
        eventHandlePromise.then((eventHandle) => eventHandle?.remove()).catch(e => console.error('remove usePushEvents event', e));
      }
    }
  }, []);

  return { received, action };
}
