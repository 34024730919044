import { Workbox } from "workbox-window";
import { useState, useEffect } from "react";

let globalState = {
  wb: null,
  registration: null,
  waiting: null,
  wasWaitingBeforeRegister: null,
  acceptReload: null,
  dismissReload: null,
  dismissed: false,
}

const listeners = new Set();


const setGlobalState = (nextGlobalState) => {
  globalState = nextGlobalState;
  listeners.forEach(listener => listener());
};

function init(wb, registration) {
  globalState.wb = wb;
  globalState.registration = registration;

  const acceptReload = () => {
    wb.addEventListener('controlling', () => {
      window.location.reload();
    });

    // This will postMessage() to the waiting service worker.
    wb.messageSkipWaiting();
  };
  const dismissReload = () => {
    setGlobalState({
      ...globalState,
      dismissed: true,
    });
  };

  // `event.wasWaitingBeforeRegister` will be false if this is
  // the first time the updated service worker is waiting.
  // When `event.wasWaitingBeforeRegister` is true, a previously
  // updated service worker is still waiting.
  // You may want to customize the UI prompt accordingly.
  const waiting = (event) => {
    setGlobalState({
      ...globalState,
      waiting: true,
      wasWaitingBeforeRegister: event.wasWaitingBeforeRegister,
      acceptReload,
      dismissReload,
    });
  };

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', waiting);
}

export async function register(swUrl) {
  const wb = new Workbox(swUrl);

  const registration = await wb.register()
  init(wb, registration);
}

export function useWorkbox() {
  const [state, setState] = useState(globalState);

  useEffect(() => {
    const listener = () => {
      setState(globalState);
    };
    listeners.add(listener);
    listener();
    return () => listeners.delete(listener);
  }, []);

  return state;
}
