import { useRef } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";


export function useTrackScroll({ track, scrollContainerRef, scrollContentRef, category, location }) {

  const trackedScrollPositionsRef = useRef([]);

  useScrollPosition(({ currPos }) => {
    const scrollTrackingSteps = [100, 200, 400, 800, 1600];
    scrollTrackingSteps.forEach(step => {
      if(currPos.y > step && !trackedScrollPositionsRef.current.includes(step)) {
        trackedScrollPositionsRef.current.push(step);
        track(`scroll_to_${step}`, { category, position: step, max: scrollContainerRef.current.scrollHeight, location: location.href });
      }
    });
  }, null, scrollContentRef, false, 100, scrollContainerRef);
}
