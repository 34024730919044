import { useEffect, useRef, useState } from "react";


export function useInputPositions(message, inputRef) {

  const caretPosition = useRef(null);
  const [currentWord, setCurrentWord] = useState('');
  const [currentWordPosition, setCurrentWordPosition] = useState(null);
  const inputEl = inputRef.current;

  const isTyping = useRef(false);
  const isTypingTimeout = useRef(false);


  useEffect(() => {
    // console.log('check current word update', { isTyping: isTyping.current, message, caretPosition: caretPosition.current, char: message[caretPosition.current - 1] })
    if(isTyping.current && message) {
      if(message[caretPosition.current - 1] === ' ') {
        setCurrentWord('');
        setCurrentWordPosition(null);
      } else if(caretPosition.current >= message.length) {
        // optimization for base case when typing at end of message
        const words = message.split(/\s/);
        const word = words[words.length-1];
        setCurrentWord(word);
        setCurrentWordPosition(message.length - word.length);
      } else {
        const words = message.split(/\s/);
        let letterCount = -1; // start on -1 as first word isn't preceded by space
        for(const word of words) {
          const endOfWordPosition = letterCount + 1 + word.length; // +1 for space removed by split
          if(caretPosition.current <= endOfWordPosition) {
            setCurrentWord(word);
            setCurrentWordPosition(endOfWordPosition - word.length);
            return;
          }
          letterCount = endOfWordPosition;
        }
        console.error("Shouldn't end up here, something wrong with algo", { caretPosition, message, words })
      }
    } else {
      setCurrentWord('');
      setCurrentWordPosition(null);
    }
  }, [message, setCurrentWord]);


  useEffect(() => {
    if(!inputEl) {
      return;
    }

    // backup function for updating caret position as selectionchange event is buggy in chrome https://bugs.chromium.org/p/chromium/issues/detail?id=725890
    function handleInput(ev) {
      const inputEl = ev.target;
      if(inputEl.selectionStart === inputEl.selectionEnd) {
        caretPosition.current = inputEl.selectionStart;
      } else {
        caretPosition.current = null;
      }
    }

    function handleKey(ev) {
      if (/(\w| |Backspace)/i.test(ev.key)) {
        isTyping.current = true;
        if(isTypingTimeout.current) {
          clearTimeout(isTypingTimeout.current);
        }
        // reset isTyping after one 60fps frame = 16.7ms
        isTypingTimeout.current = setTimeout(() => isTyping.current = false, 1/60);
      }
    }

    function handleSelection() {
      const activeElement = document.activeElement
      if (activeElement && activeElement === inputEl) {
        if(inputEl.selectionStart === inputEl.selectionEnd) {
          caretPosition.current = inputEl.selectionStart;
        } else {
          caretPosition.current = null;
        }
      } else {
        caretPosition.current = null;
      }
    }

    document.addEventListener('selectionchange', handleSelection);
    inputEl.addEventListener('keydown', handleKey);
    inputEl.addEventListener('keyup', handleKey);
    inputEl.addEventListener('beforeinput', handleKey);
    inputEl.addEventListener('input', handleInput);
    return () => {
      document.removeEventListener('selectionchange', handleSelection);
      inputEl.removeEventListener('keydown', handleKey);
      inputEl.removeEventListener('keyup', handleKey);
      inputEl.removeEventListener('beforeinput', handleKey);
      inputEl.removeEventListener('input', handleInput);
    };
  }, [inputEl]);


  return { currentWord, caretPosition, currentWordPosition };
}
