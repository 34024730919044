import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import smartQueue from '@analytics/queue-utils';
import { onLCP, onCLS, onFCP, onTTFB, onINP } from 'web-vitals';

import { useAppIsActive } from './app-state';
import { useRemoveParams, StringParam } from './remove-params';
import { useReferrer } from './history';

const debug = false;

// Queue options
const options = {
  max: 40, // limit
  interval: 500, // 0.5s
  throttle: true, // Ensure only max is processed at interval
  onPause: () => {
    debug &&  console.log('analytics | queue | paused');
  },
  onEmpty: () => {
    debug && console.log('analytics | queue | empty');
  }
}

const queue = smartQueue((items, restOfQueue) => {
  debug && console.log(`analytics | queue | processing ${items.length}, ${restOfQueue.length} left`);
  items.forEach(worker => worker());
}, options);

export function useAnalyticsQueued() {

  const { page, track, identify } = useAnalytics();

  return {
    // delay page so meta dags have time to be updated
    page:  useCallback(( event, payload ) => queue.push(() => setTimeout(() => page(event, payload), 100)), [ page ]),
    track:  useCallback(( event, payload ) => queue.push(() => track(event, payload)), [ track ]),
    identify:  useCallback(( event, payload ) => queue.push(() => identify(event, payload)), [ identify ]),
  };
}


export function useAnalyticsSetup() {

  const appIsActive = useAppIsActive();
  const location = useLocation();
  const { page, track } = useAnalyticsQueued();
  const landingTracking = useReferrer();

  const [ removableParams, removeParams ] = useRemoveParams({
    'utm_campaign': StringParam,
    'utm_medium': StringParam,
    'utm_source': StringParam,
    'utm_content': StringParam,
    'invite_to': StringParam,
    'link_by': StringParam,
  });

  useEffect(() => {
    if (location) {
      debug && console.log('analytics | setup | location');
      page({ campaignParams: landingTracking });
      const utms = [ 'utm_campaign', 'utm_medium', 'utm_source', 'utm_content' ];
      if(Object.entries(removableParams).some(([key, value]) => utms.includes(key) && value !== undefined)) {
        removeParams(utms);
      }
    }
  }, [ location, landingTracking, removableParams, removeParams, page ]);

  const previousActiveState = useRef(false);
  useEffect(() => {
    if(previousActiveState.current !== appIsActive) {
      previousActiveState.current = appIsActive;
      debug && console.log('analytics | setup | app state change');
      if (appIsActive) {
        queue.resume();
      } else {
        queue.pause();
      }
    }
  }, [ appIsActive, previousActiveState, page, location ]);

  useEffect(() => {
    const trackVital = ({name, delta, value, id}) => {
      // See https://github.com/GoogleChrome/web-vitals
      track(`web_vitals_${name}`, {
        name,
        category: 'performance',
        value: delta, // Use `delta` so the value can be summed.
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.
      });
    }
    onCLS(trackVital);
    onFCP(trackVital);
    onINP(trackVital);
    onLCP(trackVital);
    onTTFB(trackVital);
  }, [ track ]);
}

