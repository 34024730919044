import { useReactiveVar } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { loginPushVar } from "../vars/login-push";
import { useAnalyticsQueued } from "./delicious-analytics";


export function useLoginPush() {

  const loginPush = useReactiveVar(loginPushVar);
  const { track } = useAnalyticsQueued();

  const setLoginHint = useCallback((loginHint: string) => {
    const before = loginPushVar();
    if(loginHint) {
      track('login_hint', { category: 'login-push', loginHint });
    }
    loginPushVar({
      ...before,
      loginHint,
    });
  }, [track]);

  const setDisplayState = useCallback((displayState: 'hidden' | 'show' | 'initial') => {
    const before = loginPushVar();
    const hasShown = displayState === 'show' ? true : before.hasShown;
    track('login_display_state', { category: 'login-push', displayState, hasShown: before.hasShown });
    loginPushVar({
      ...before,
      displayState,
      hasShown,
    });
  }, [track]);

  const state = useMemo(() => {
    return {
      ...loginPush,
      setLoginHint,
      setDisplayState,
    };
  }, [loginPush, setLoginHint, setDisplayState]);

  return state;
}
