import { useCallback } from "react";

import { AddCommentReactionMutation, AddReactionMutation, Emoji, useAddCommentReactionMutation, useAddReactionMutation } from "../generated/graphql";
import { useSession } from "./auth";


const likeEmoji = {
  "id": "icecream",
  "name": "Soft Ice Cream",
  "colons": ":icecream:",
  "emoticons": [],
  "unified": "1f366",
  "skin": null,
  "native": "🍦",
  "shortNames": [
    "icecream"
  ]
} as Emoji;


export type AddReactionShare = NonNullable<AddReactionMutation['addReaction']>;
export type AddCommentReactionShare = NonNullable<AddCommentReactionMutation['addReaction']>;


export function useAddReaction() {

  const { user } = useSession();
  const [addReactionMutation] = useAddReactionMutation();

  const addReaction = useCallback((share: AddReactionShare, emoji: Emoji, selected: boolean) => {
    if(!user) {
      console.error('Cannot add reaction when not authenticated');
      return;
    }

    const optimisticReactions = share.reactions ? [...share.reactions] : [];
    const reactionIndex = optimisticReactions.findIndex(r => r.emoji.id === emoji.id);
    const contact = {
      _id: user._id as string,
      __typename: 'Contact' as const,
    };
    if(selected) {
      if(reactionIndex === -1) {
        optimisticReactions.push({
          __typename: 'Reaction',
          emoji,
          reacted: [contact],
        });
      } else {
        optimisticReactions[reactionIndex] = {
          ...optimisticReactions[reactionIndex],
          reacted: [...optimisticReactions[reactionIndex].reacted, contact]
        };
      }
    } else {
      if(reactionIndex === -1) {
        console.warn('Reaction missing when trying to remove it');
      } else {
        const optimisticReacted = optimisticReactions[reactionIndex].reacted.filter(c => c._id !== contact._id)
        optimisticReactions[reactionIndex] = {
          ...optimisticReactions[reactionIndex],
          reacted: optimisticReacted,
        };
      }
    }

    addReactionMutation({
      variables: {
        input: {
          shareId: share._id,
          emoji,
          selected,
        }
      },
      optimisticResponse: {
        addReaction: {
          ...share,
          reactions: optimisticReactions,
        }
      },
    });
  }, [addReactionMutation, user]);

  return addReaction;
}


export function useAddLike() {
  const addReaction = useAddReaction();
  return useCallback((share: AddReactionShare, selected: boolean) => {
    addReaction(share, likeEmoji, selected);
  }, [addReaction]);
}


export function useAddCommentLike() {

  const { user } = useSession();
  const [addReactionMutation] = useAddCommentReactionMutation();

  const emoji = likeEmoji;

  const addCommentLike = useCallback((share: AddCommentReactionShare, commentId: string, selected: boolean) => {
    if(!user) {
      console.error('Cannot add reaction when not authenticated');
      return;
    }

    const optimisticComments = [...share.comments];
    const commentIndex = optimisticComments.findIndex(c => c._id.toString() === commentId);
    const comment = optimisticComments[commentIndex];
    const optimisticReactions = comment ? [...comment.reactions] : [];
    const reactionIndex = optimisticReactions.findIndex(r => r.emoji.id === emoji.id);
    const contact = {
      _id: user._id as string,
      __typename: 'Contact' as const,
    };
    if(commentIndex === -1) {
      console.warn('Comment missing when trying to add like');
    } else {
      if(selected) {
        if(reactionIndex === -1) {
          optimisticReactions.push({
            __typename: 'Reaction',
            emoji,
            reacted: [contact],
          });
        } else {
          optimisticReactions[reactionIndex] = {
            ...optimisticReactions[reactionIndex],
            reacted: [...optimisticReactions[reactionIndex].reacted, contact]
          };
        }
      } else {
        if(reactionIndex === -1) {
          console.warn('Reaction missing when trying to remove it');
        } else {
          const optimisticReacted = optimisticReactions[reactionIndex].reacted.filter(c => c._id !== contact._id)
          optimisticReactions[reactionIndex] = {
            ...optimisticReactions[reactionIndex],
            reacted: optimisticReacted,
          };
        }
      }
      optimisticComments[commentIndex] = {
        ...comment,
        reactions: optimisticReactions,
      };
    }

    addReactionMutation({
      variables: {
        input: {
          shareId: share._id,
          commentId,
          emoji,
          selected,
        }
      },
      optimisticResponse: {
        addReaction: {
          ...share,
          comments: optimisticComments,
        }
      },
    });
  }, [addReactionMutation, user, emoji]);

  return addCommentLike;
}
