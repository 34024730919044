
import { useMemo } from 'react';
import { useQuery } from "@apollo/client";

import { GET_USER_ACTIONS } from "../queries/user";


export function useCompleteness() {

  const { data, loading, error } = useQuery(GET_USER_ACTIONS);

  const {
    follows,
    invites,
    privateLists,
    publicLists,
    // followedLists,
    publicShares,
    groupShares,
    // listShares,
    listItems,
    avatar,
    createdGroups,
    // pushEnabled,
  } = data?.currentUser?.actions || {};

  const actions = useMemo(() => ({
    follows: { value: follows, goal: 5 },
    invites: { value: invites, goal: 1 },
    collections: { value: privateLists + publicLists, goal: 2 },
    // followedLists,
    shares: { value: publicShares + groupShares, goal: 4 },
    // listShares,
    listItems: { value: listItems, goal: 4 },
    avatar: { value: +avatar, goal: 1 },
    createdGroups: { value: createdGroups, goal: 1 },
    // pushEnabled,
  }), [ follows, invites, privateLists, publicLists, publicShares, groupShares, listItems, avatar, createdGroups ]);

  const total = Object.values(actions).reduce((acc, item) => acc + item.goal, 0);
  const progress = Object.values(actions).reduce((acc, item) => acc + Math.min(item.value, item.goal), 0);
  const progressPct = Math.floor(100 * progress / total);

  return useMemo(() => ({ data: { actions: data?.currentUser?.actions ? actions : false, total, progress, progressPct }, loading, error }), [ data, loading, error, actions, total, progress, progressPct ]);
}
