import { useState, useEffect, useRef } from "react";
import { useInView } from 'react-intersection-observer';

export function useIsImpression({ duration }) {

  const [ isImpression, setIsImpression ] = useState();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1
  });

  const timerId = useRef(null);
  useEffect(() => {
    if (!inView && timerId.current) {
      clearTimeout(timerId.current);
    }
    if (inView) {
      timerId.current = setTimeout(() => {
        setIsImpression(true);
      }, duration);
    }
    return () => timerId.current && clearTimeout(timerId.current);
  }, [inView, duration]);

  return [ ref, isImpression ];
}
