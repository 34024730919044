import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { onAuthStateChanged, signInWithCustomToken, getRedirectResult, getAdditionalUserInfo } from 'firebase/auth';

import { setUser } from '../utils/sentry';
import { firebaseAuth } from '../firebase';
import { useRemoveParams, StringParam } from './remove-params';
import { ACCEPT_INVITATION, ADD_EMAIL, GET_USER_DATA, USER_SUBSCRIPTION } from '../queries/user';
import { VERIFY_TOKEN } from '../queries/auth';
import { invitationTokenVar } from '../vars/invitation-token';
import { useAnalyticsQueued } from './delicious-analytics';


const userContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  return <userContext.Provider value={auth}>{children}</userContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

/**
 *
 * @returns {{
 * initializingFirebase: boolean,
 * auth: firebase.User,
 * initializing: boolean,
 * user: import('../generated/graphql').Person,
 * loading: boolean,
 * userLoading: boolean,
 * error: ApolloError,
 * refetch: () => void,
 * signinUsingToken: () => void,
 * tokenError: Error,
 * setTokenError: () => void,
 * oAuthError: Error
 * }}
 */
export const useSession = () => {
  return useContext(userContext);
}

const useAuth = () => {

  const client = useApolloClient();
  const { track, identify } = useAnalyticsQueued();


  const [state, setState] = useState(() => {
    const auth = firebaseAuth?.currentUser;

    const initialState = {
      initializingFirebase: !auth,
      auth,
      initializing: true,
      user: null,
      loading: false,
      userLoading: false,
      tokenLoading: false,
      error: null,
      usingToken: null,
      refetch: () => {  },
      signinUsingToken: null,
      tokenError: null,
      setTokenError: (tokenError) => { setState(s => ({...s, tokenError })) },
      oAuthError: null
    };
    return initialState;
  });

  const { data, loading: userLoading, error, refetch, subscribeToMore } = useQuery(GET_USER_DATA, {
    skip: !state.auth?.getIdToken(),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: "cache-first",
    context: {
      headers: {
        batchKey: 'critical-path', // Don't batch query on critical path
      }
    }
  });

  useEffect(() => {
    if(state.user) {
      identify(state.user._id, { email: state.user.email, name: state.user.username });
    }
  }, [ state.user, identify ]);


  useEffect(() => {
    subscribeToMore({
      document: USER_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        console.log('currentUserUpdated updateQuery', { prev, subscriptionData })
        if (!subscriptionData.data) {
          return prev;
        }
        refetch();
        return prev;
      },
    });
  }, [subscribeToMore, refetch]);


  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      user: data?.currentUser,
      userLoading,
      loading: userLoading || state.tokenLoading,
      error,
      refetch,
    }))
  }, [data, userLoading, error, refetch, state.tokenLoading]);


  useEffect(() => {
    const onChange = (auth) => {
      setState(prevState => {
        if (!auth?.email) {
          auth = null;
        }
        if(auth && !prevState.auth) {
          track('auth_state_change_login', { category: 'auth' });
          client.reFetchObservableQueries();
          setUser({ email: auth.email });
          prevState.refetch();
        } else if(!auth && prevState.auth) {
          client.resetStore();
        } else {
          // console.log(`onAuthStateChanged with no update`, { newAuth: auth, oldAuth: prevState.auth });
        }

        return {
          ...prevState,
          initializingFirebase: false,
          auth,
          user: auth ? prevState.user : null
        };
      });
    };

    getRedirectResult(firebaseAuth)
      .then(userCredential => {
        if (userCredential) {
          const providerId = userCredential.providerId ? userCredential.providerId.replace('.', '_') : 'unknown';
          const userInfo = getAdditionalUserInfo(userCredential);
          console.log(`${userCredential.providerId} - getRedirectResult - ${userCredential.operationType} - success`, { userCredential, userInfo });
          if (userInfo?.isNewUser) {
            track('signup', { category: 'auth', action: userCredential.operationType, providerId });
          } else {
            track('login', { category: 'auth', action: userCredential.operationType, providerId });
          }
          track(`${providerId}_oauth_${userCredential.operationType}`, { category: 'auth', action: userCredential.operationType });
        }
      })
      .catch(error => {
        setState(prevState => ({
          ...prevState,
          oAuthError: error,
        }));
      });

    // listen for auth state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, [ client, track ]);


  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      initializing: prevState.initializing && (state.initializingFirebase || (state.loading && !state.user))
    }));
  }, [state.initializingFirebase, state.loading, state.user]);


  useEffect(() => {
    const signinUsingToken = (token) => {
      if(state.usingToken === token) {
        console.warn('signinUsingToken skipping because reuse same token');
      }
      setState(prevState => ({ ...prevState, usingToken: token, tokenLoading: true }));
      console.log('signinUsingToken verifyToken', { token });

      return client.mutate({
        mutation: VERIFY_TOKEN,
        variables: { token },
      }).then((response) => {
        if(!response?.data?.verifyToken) {
          throw new Error('Failed to authenticate with token');
        }
        const firebaseCustomToken = response.data.verifyToken.firebaseCustomToken;
        console.log('signinUsingToken signInWithCustomToken', { firebaseCustomToken });
        return signInWithCustomToken(firebaseAuth, firebaseCustomToken).then((firebaseCredential) => {
          setState(prevState => ({ ...prevState, tokenLoading: false }));
          console.log('signinUsingToken firebaseCredential', { firebaseCredential });
          return firebaseCredential;
        });
      }).catch((err) => {
        setState(prevState => ({ ...prevState, tokenLoading: false }));
        throw err;
      });

    };
    setState(prevState => ({ ...prevState, signinUsingToken }));
  }, [client, state.usingToken]);

  useAcceptInvitation(state.user);

  useRetryAuthOnError(refetch, error);

  useTokenAuth(state);

  // Don't know what this is for, perhaps retry after clearing error?
  // Initially added in commit 0845f16adbc8f52be4dcc1b3c765a171813e5d32 on 2021-02-05
  // /P 2022-06-01
  if(state.auth && !state.user) {
    if(!state.loading && !error) {
      refetch();
    }
  }

  return state;
}


function useRetryAuthOnError(refetch, error) {
  const [retryTimeout, setRetryTimeout] = useState(null);
  const retryAuth = useCallback(function() {
    if(!retryTimeout) {
      refetch().catch(() => {
        const timeout = setTimeout(retryAuth, 5*1000);
        setRetryTimeout(timeout);
      })
    }
  }, [refetch, setRetryTimeout, retryTimeout]);

  useEffect(() => {
    if(error) {
      retryAuth();
    } else if(retryTimeout) {
      clearTimeout(retryTimeout);
    }
  }, [error, retryAuth, retryTimeout]);
}


function useAcceptInvitation(user) {

  const urlParamName = 'invitation_token';
  const { track } = useAnalyticsQueued();

  const [ removableParams, removeParams ] = useRemoveParams({
    [urlParamName]: StringParam,
  });

  const [ acceptInvitation, { called } ] = useMutation(ACCEPT_INVITATION);

  const invitationToken = useReactiveVar(invitationTokenVar);

  useEffect(() => {
    if(removableParams[urlParamName]) {
      invitationTokenVar(removableParams[urlParamName]);
      removeParams([urlParamName]);
    }
  }, [ removableParams, removeParams ]);

  useEffect(() => {
    // use accept invitation token after login
    if(user && invitationToken && !called) {
      acceptInvitation({ variables: { input: { invitationKey: invitationToken, email: user.email } } })
        .catch(err => {
          console.error('acceptInvitation error', err);
        })
        .finally(() => {
          track('accept_invitation', { category: 'auth' });
          invitationTokenVar(null);
        });
    }
  }, [ user, invitationToken, acceptInvitation, called, removeParams, removableParams, track ]);

}


function useTokenAuth({ initializing, auth, user, signinUsingToken, usingToken, refetch, setTokenError }) {

  const [ removableParams, removeParams ] = useRemoveParams({
    'token': StringParam,
  });
  const { track } = useAnalyticsQueued();

  const [addEmail] = useMutation(ADD_EMAIL, {
    onCompleted: refetch
  });

  useEffect(() => {

    const removeToken = () => {
      removeParams([ 'token' ]);
    }

    if(!initializing && removableParams.token && usingToken !== removableParams.token) {
      if(!auth) {
        signinUsingToken(removableParams.token)
          .then(() => {
            track('token_login', { category: 'auth' });
            removeToken();
          })
          .catch((error) => {
            console.error('signinUsingToken 1', error.message);
            setTokenError(error);
          })
          .finally(removeToken);
      } else if(user) {
        const oldAuth = auth;
        const oldUser = user;
        signinUsingToken(removableParams.token).then(firebaseUserCredential => {
          const newAuth = firebaseUserCredential.user;
          if(newAuth.email !== oldAuth.email && !oldUser.emails.includes(newAuth.email)) {
            // ask if merge accounts
            if(confirm(`Merge accounts ${oldUser.email} with ${newAuth.email}?`)) {
              addEmail({ variables: { email: oldUser.email } });
            }
          }
        }).catch((error) => {
          console.error('signinUsingToken 2', error.message);
        }).finally(removeToken);
      }
    }
  }, [ initializing, removableParams.token, removeParams, auth, user, usingToken, signinUsingToken, addEmail, setTokenError, track ]);
}
